import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'frente-caixa',
    loadChildren: () => import('./frente-caixa/frente-caixa.module').then( m => m.FrenteCaixaPageModule)
  },
  {
    path: 'clientes',
    loadChildren: () => import('./clientes/clientes.module').then( m => m.ClientesPageModule)
  },
  {
    path: 'novo-cliente',
    loadChildren: () => import('./novo-cliente/novo-cliente.module').then( m => m.NovoClientePageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'categoria',
    loadChildren: () => import('./categoria/categoria.module').then( m => m.CategoriaPageModule)
  },
  {
    path: 'nova-categoria',
    loadChildren: () => import('./nova-categoria/nova-categoria.module').then( m => m.NovaCategoriaPageModule)
  },
  {
    path: 'produtos',
    loadChildren: () => import('./produtos/produtos.module').then( m => m.ProdutosPageModule)
  },
  {
    path: 'novo-produto',
    loadChildren: () => import('./novo-produto/novo-produto.module').then( m => m.NovoProdutoPageModule)
  },
  {
    path: 'nova-venda',
    loadChildren: () => import('./nova-venda/nova-venda.module').then( m => m.NovaVendaPageModule)
  },
  {
    path: 'venda',
    loadChildren: () => import('./venda/venda.module').then( m => m.VendaPageModule)
  },
  {
    path: 'carrinho',
    loadChildren: () => import('./carrinho/carrinho.module').then( m => m.CarrinhoPageModule)
  },
  {
    path: 'gerar-nfe',
    loadChildren: () => import('./gerar-nfe/gerar-nfe.module').then( m => m.GerarNFePageModule)
  },
  {
    path: 'ver-nfe',
    loadChildren: () => import('./ver-nfe/ver-nfe.module').then( m => m.VerNfePageModule)
  },
  {
    path: 'transportadoras',
    loadChildren: () => import('./transportadoras/transportadoras.module').then( m => m.TransportadorasPageModule)
  },
  {
    path: 'fornecedores',
    loadChildren: () => import('./fornecedores/fornecedores.module').then( m => m.FornecedoresPageModule)
  },
  {
    path: 'novo-fornecedor',
    loadChildren: () => import('./novo-fornecedor/novo-fornecedor.module').then( m => m.NovoFornecedorPageModule)
  },
  {
    path: 'manifesto',
    loadChildren: () => import('./manifesto/manifesto.module').then( m => m.ManifestoPageModule)
  },
  {
    path: 'config-emitente',
    loadChildren: () => import('./config-emitente/config-emitente.module').then( m => m.ConfigEmitentePageModule)
  },
  {
    path: 'venda-caixa',
    loadChildren: () => import('./venda-caixa/venda-caixa.module').then( m => m.VendaCaixaPageModule)
  },
  {
    path: 'gerar-nfce',
    loadChildren: () => import('./gerar-nfce/gerar-nfce.module').then( m => m.GerarNfcePageModule)
  },
  {
    path: 'ver-nfce',
    loadChildren: () => import('./ver-nfce/ver-nfce.module').then( m => m.VerNfcePageModule)
  },
  {
    path: 'conta-receber',
    loadChildren: () => import('./conta-receber/conta-receber.module').then( m => m.ContaReceberPageModule)
  },
  {
    path: 'conta-pagar',
    loadChildren: () => import('./conta-pagar/conta-pagar.module').then( m => m.ContaPagarPageModule)
  },
  {
    path: 'nova-conta-receber',
    loadChildren: () => import('./nova-conta-receber/nova-conta-receber.module').then( m => m.NovaContaReceberPageModule)
  },
  {
    path: 'receber-conta',
    loadChildren: () => import('./receber-conta/receber-conta.module').then( m => m.ReceberContaPageModule)
  },
  {
    path: 'nova-conta-pagar',
    loadChildren: () => import('./nova-conta-pagar/nova-conta-pagar.module').then( m => m.NovaContaPagarPageModule)
  },
  {
    path: 'pagar-conta',
    loadChildren: () => import('./pagar-conta/pagar-conta.module').then( m => m.PagarContaPageModule)
  },
  {
    path: 'caixa',
    loadChildren: () => import('./caixa/caixa.module').then( m => m.CaixaPageModule)
  },
  {
    path: 'orcamento',
    loadChildren: () => import('./orcamento/orcamento.module').then( m => m.OrcamentoPageModule)
  },
  {
    path: 'inventario',
    loadChildren: () => import('./inventario/inventario.module').then( m => m.InventarioPageModule)
  },
  {
    path: 'apontar',
    loadChildren: () => import('./apontar/apontar.module').then( m => m.ApontarPageModule)
  },
  {
    path: 'inventario-list',
    loadChildren: () => import('./inventario-list/inventario-list.module').then( m => m.InventarioListPageModule)
  },
  {
    path: 'home-comanda',
    loadChildren: () => import('./home-comanda/home-comanda.module').then( m => m.HomeComandaPageModule)
  },
  {
    path: 'comanda-view',
    loadChildren: () => import('./comanda-view/comanda-view.module').then( m => m.ComandaViewPageModule)
  },
  {
    path: 'pre-venda',
    loadChildren: () => import('./pre-venda/pre-venda.module').then( m => m.PreVendaPageModule)
  },
  {
    path: 'lista-pre-venda',
    loadChildren: () => import('./lista-pre-venda/lista-pre-venda.module').then( m => m.ListaPreVendaPageModule)
  },
  {
    path: 'orcamento-edit',
    loadChildren: () => import('./orcamento-edit/orcamento-edit.module').then( m => m.OrcamentoEditPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
