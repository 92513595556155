import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserStorage{

  constructor(
  ) { }

  setUser(usuario, path, token, id, img, empresa_id, controle_comandas, impressora, contas_por_vendedor) {
    let js = {
      usuario: usuario,
      path: path,
      token: token,
      id: id,
      img: img,
      empresa_id: empresa_id,
      controle_comandas: controle_comandas,
      impressora: impressora,
      contas_por_vendedor: contas_por_vendedor
    }

    window.localStorage.setItem("credenciais", JSON.stringify(js));
  }

  getUser() {
    return window.localStorage.getItem("credenciais") ? JSON.parse(window.localStorage.getItem("credenciais")) : null;
  }

  getPath() {
    return JSON.parse(window.localStorage.getItem("credenciais"));
  }

  setDados(img, nome, tipo) {
    let js = {
      img: img,
      nome: nome,
      tipo: tipo
    }
    window.localStorage.setItem("dadosUsuario", JSON.stringify(js));
  }

  getDados() {
    return JSON.parse(window.localStorage.getItem("dadosUsuario"));
  }

}
