import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStorage } from 'src/storage/user.storage';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  path = '';
  storage = null;
  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  requestOptions = null;
  constructor(
    private http: HttpClient,
    private userStorage: UserStorage
  ) {
    let storage = this.storage = this.userStorage.getUser()
    if (storage != null) {
      this.path = storage.path + '/api';

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'text/javascript',
        'token': storage.token
      });
      this.requestOptions = headers
    }

  }

  getConfig(): Observable<any> {
    if (this.path != "" && this.path != null) {
      return this.http.get<any>(this.path + '/appFiscal/configEmitente', { headers: this.requestOptions });
    } else {
      return null;
    }
  }

  salvar(emitente): Observable<any> {
    emitente.empresa_id = this.storage.empresa_id
    return this.http.post<any>(this.path + '/appFiscal/configEmitente/salvar', emitente);
  }

  getDadosCertificado(): Observable<any> {
    return this.http.get<any>(this.path + '/appFiscal/configEmitente/dadosCertificado', { headers: this.requestOptions });
  }

  salvarCertificado(file, senha): Observable<any> {
    // headers
    let httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data;'
      })
    };

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('senha', senha);

    return this.http
      .post<any>(this.path + '/appFiscal/configEmitente/salvarCertificado', formData, httpOptions)
  }

}
