import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { UserStorage } from 'src/storage/user.storage';
import { ApiConfig } from 'src/config/api.config';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  menuAtivo = ''
  imgUser = ''
  nomeUser = ''
  appNome = ''
  pages: any = []

  user;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private menu: MenuController,
    private userStorage: UserStorage,
    private configService: ConfigService
  ) {
    this.appNome = ApiConfig.appNome
    // if (this.platform.is('ios')) {
    //   console.log('ios')
    // } else {
    //   console.log('android')
    //   this.androidFullScreen.isImmersiveModeSupported()
    //     .then(() => {
    //       console.log('Immersive mode supported')
    //       this.androidFullScreen.immersiveHeight()
    //         .then(() => {
    //           console.log("Ativado")
    //         })
    //         .catch(err =>
    //           console.log("erro:", err)
    //         );
    //     })
    //     .catch(err =>
    //       console.log("erro:", err)
    //     );
    // }
    this.initializeApp();
    this.user = this.userStorage.getUser();
    if (this.user == null) {
      this.config()
    } else {
      // this.verificaConectado()
      if (this.user.img == "") {
        this.imgUser = this.user.path + '/foto_usuario/user.png'
      } else {
        this.imgUser = this.user.path + '/foto_usuario/' + this.user.img
      }
      this.initMenu(this.user)
      this.nomeUser = this.user.usuario
    }
  }

  initMenu(user) {
    if (user.controle_comandas == false) {
      this.pages = [
        {
          icon: 'home-outline',
          nome: 'Home',
          route: 'home'
        },
        {
          icon: 'wallet-outline',
          nome: 'Frente de Caixa NFCe',
          route: 'frente-caixa'
        },
        {
          icon: 'apps-outline',
          nome: 'Pré Venda',
          route: 'pre-venda'
        },
        {
          icon: 'people-circle-outline',
          nome: 'Clientes',
          route: 'clientes'
        },
        {
          icon: 'file-tray-full-outline',
          nome: 'Fornecedores',
          route: 'fornecedores'
        },

        {
          icon: 'folder-outline',
          nome: 'Categorias',
          route: 'categoria'
        },

        {
          icon: 'pricetags-outline',
          nome: 'Produtos',
          route: 'produtos'
        },

        {
          icon: 'bookmark-outline',
          nome: 'Vendas NFe',
          route: 'venda'
        },
        {
          icon: 'pizza-outline',
          nome: 'Controle de comandas',
          route: 'home-comanda'
        },
        {
          icon: 'clipboard-outline',
          nome: 'Orçamentos',
          route: 'orcamento'
        },

        {
          icon: 'cash-outline',
          nome: 'Contas a Receber',
          route: 'conta-receber'
        },

        {
          icon: 'calculator-outline',
          nome: 'Contas a Pagar',
          route: 'conta-pagar'
        },

        {
          icon: 'cube-outline',
          nome: 'Caixa',
          route: 'caixa'
        },

        {
          icon: 'bookmarks-outline',
          nome: 'Inventario',
          route: 'inventario'
        },

        {
          icon: 'library-outline',
          nome: 'Manifesto',
          route: 'manifesto'
        },

        {
          icon: 'cog-outline',
          nome: 'Emitente',
          route: 'config-emitente'
        },
      ];
    }else{
      this.pages = [
        {
          icon: 'home-outline',
          nome: 'Home',
          route: 'home-comanda'
        }
      ]
    }
  }

  verificaConectado() {
    this.configService.getConfig().
      subscribe(
        data => {

        }, err => {
          this.imgUser = '../../assets/images/user.png'
        }
      )
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.user = this.userStorage.getUser();
      
      // this.statusBar.styleDefault();
      var styles = getComputedStyle(document.documentElement)
      let color = String(styles.getPropertyValue('--ion-color-principal')).trim()
      this.statusBar.backgroundColorByHexString(color);
      this.splashScreen.hide();
    });
  }

  setPage(route) {
    this.user = this.userStorage.getUser();
    if (this.user == null) {
      this.config()
    } else {
      this.menuAtivo = route
      this.menu.toggle()
      this.router.navigateByUrl(route);
    }
  }

  config() {
    this.router.navigateByUrl('config');
    this.menu.toggle()
  }

  alterarDados() {
    this.router.navigateByUrl('dados-usuario');
    this.menu.toggle()
  }
}
