import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataStorage implements OnInit{

  constructor(
    private storage: Storage
  ) { }

  async ngOnInit() {
    await this.storage.create();
  }

  async setStorage(data, key) {
    await this.storage.set(key, JSON.stringify(data));
  }

  async getStorage(key){
    return await this.storage.get(key);
  }

  async removeStorage(key){
    return await this.storage.remove(key);
  }
}
